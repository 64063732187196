import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "contributing"
    }}>{`Contributing`}</h1>
    <p>{`Hi!  👋
We’re excited that you’re interested in contributing!
Take a moment to read the following guidelines.
And thanks for contributing to `}<strong parentName="p">{`MDX`}</strong>{`!  👏👌✨`}</p>
    <p>{`If you’re raising an issue, please understand that people involved with this
project often do so for fun, next to their day job; you are not entitled to
free customer service.`}</p>
    <h2 {...{
      "id": "table-of-contents"
    }}>{`Table of Contents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#questions"
        }}>{`Questions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#contributions"
        }}>{`Contributions`}</a><ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#financial-support"
            }}>{`Financial support`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#improve-documentation"
            }}>{`Improve documentation`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#improve-issues"
            }}>{`Improve issues`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#give-feedback-on-issues-and-pull-requests"
            }}>{`Give feedback on issues and pull requests`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#write-code"
            }}>{`Write code`}</a></li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#running-the-tests"
        }}>{`Running the tests`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#submitting-an-issue"
        }}>{`Submitting an issue`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#submitting-a-pull-request"
        }}>{`Submitting a pull request`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#project-structure"
        }}>{`Project structure`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#releases"
        }}>{`Releases`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#troubleshooting"
        }}>{`Troubleshooting`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#resources"
        }}>{`Resources`}</a></li>
    </ul>
    <h2 {...{
      "id": "questions"
    }}>{`Questions`}</h2>
    <p>{`Please chat and ask questions on `}<a parentName="p" {...{
        "href": "https://spectrum.chat/mdx"
      }}>{`spectrum`}</a>{`!
Jump in there and lurk, talk to us, and help others.`}</p>
    <h2 {...{
      "id": "contributions"
    }}>{`Contributions`}</h2>
    <p>{`There’s several ways to contribute, not just by writing code.`}</p>
    <h3 {...{
      "id": "financial-support"
    }}>{`Financial support`}</h3>
    <p>{`It’s possible to support us financially by becoming a backer or sponsor of
unified through `}<a parentName="p" {...{
        "href": "https://opencollective.com/unified"
      }}>{`Open Collective`}</a>{`.
With this support, we can pay for project leadership, finance non-coding work,
or to do fun things for the community like getting stickers for contributors.
You’ll be helping unified’s maintainers manage and improve existing projects,
and additionally support our work to develop new and exciting projects, such
as `}<a parentName="p" {...{
        "href": "https://github.com/micromark/micromark"
      }}>{`micromark`}</a>{`.`}</p>
    <h3 {...{
      "id": "improve-documentation"
    }}>{`Improve documentation`}</h3>
    <p>{`As a user of this project you’re perfect for helping us improve our docs.
Typo corrections, error fixes, better explanations, new examples, etcetera.
Anything!`}</p>
    <h3 {...{
      "id": "improve-issues"
    }}>{`Improve issues`}</h3>
    <p>{`Some issues lack information, aren’t reproducible, or are just incorrect.
Help make them easier to resolve.`}</p>
    <h3 {...{
      "id": "give-feedback-on-issues-and-pull-requests"
    }}>{`Give feedback on issues and pull requests`}</h3>
    <p>{`We’re always looking for more opinions on discussions in the issue tracker.`}</p>
    <h3 {...{
      "id": "write-code"
    }}>{`Write code`}</h3>
    <p>{`Code contributions are very welcome.  It’s often good to first create an issue
to report a bug or suggest a new feature before creating a pull request to
prevent you from doing unnecessary work.`}</p>
    <h2 {...{
      "id": "running-the-tests"
    }}>{`Running the tests`}</h2>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`yarn`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`yarn bootstrap`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`yarn test`}</inlineCode></li>
    </ol>
    <p>{`Tests for an individual package can be run as a yarn workspace:
`}<inlineCode parentName="p">{`yarn workspace remark-mdx test`}</inlineCode>{`.  To see what packages ar available to test
you can list out all workspaces with `}<inlineCode parentName="p">{`yarn workspaces info`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "submitting-an-issue"
    }}>{`Submitting an issue`}</h2>
    <ul>
      <li parentName="ul">{`The issue tracker is for issues.  Use chat for support`}</li>
      <li parentName="ul">{`Search the issue tracker (including closed issues) before opening a new
issue`}</li>
      <li parentName="ul">{`Ensure you’re using the latest version of our packages`}</li>
      <li parentName="ul">{`Use a clear and descriptive title`}</li>
      <li parentName="ul">{`Include as much information as possible: steps to reproduce the issue,
error message, version, operating system, etcetera`}</li>
      <li parentName="ul">{`The more time you put into an issue, the better we will be able to help you`}</li>
      <li parentName="ul">{`The best issue report is a `}<a parentName="li" {...{
          "href": "https://twitter.com/sindresorhus/status/579306280495357953"
        }}>{`failing test`}</a>{` proving it`}</li>
    </ul>
    <h2 {...{
      "id": "submitting-a-pull-request"
    }}>{`Submitting a pull request`}</h2>
    <ul>
      <li parentName="ul">{`Non-trivial changes are often best discussed in an issue first, to prevent
you from doing unnecessary work`}</li>
      <li parentName="ul">{`For ambitious tasks, you should try to get your work in front of the
community for feedback as soon as possible`}</li>
      <li parentName="ul">{`New features should be accompanied with tests and documentation`}</li>
      <li parentName="ul">{`Don’t include unrelated changes`}</li>
      <li parentName="ul">{`Test before submitting code by running `}<inlineCode parentName="li">{`yarn test`}</inlineCode></li>
      <li parentName="ul">{`Write a convincing description of why we should land your pull request:
it’s your job to convince us`}</li>
    </ul>
    <h2 {...{
      "id": "project-structure"
    }}>{`Project structure`}</h2>
    <p>{`MDX is a monorepo that uses `}<a parentName="p" {...{
        "href": "https://lernajs.io"
      }}>{`lerna`}</a>{`.`}</p>
    <ul>
      <li parentName="ul">{`All packages are found in `}<inlineCode parentName="li">{`./packages`}</inlineCode></li>
      <li parentName="ul">{`All documentation is found in `}<inlineCode parentName="li">{`./docs`}</inlineCode>{` and can be viewed with `}<inlineCode parentName="li">{`yarn docs -- -o`}</inlineCode></li>
      <li parentName="ul">{`There’s an `}<inlineCode parentName="li">{`./examples`}</inlineCode>{` directory where examples for different tools and
frameworks`}</li>
    </ul>
    <h2 {...{
      "id": "releases"
    }}>{`Releases`}</h2>
    <p>{`In order to release a new version you can follow these steps:`}</p>
    <ul>
      <li parentName="ul">{`Draft a release for the next version (vX.X.X)`}</li>
      <li parentName="ul">{`Release a prerelease`}<ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`yarn lerna publish`}</inlineCode></li>
          <li parentName="ul">{`Select prepatch/preminor/premajor`}</li>
          <li parentName="ul">{`Sanity check in a project or two with the prerelease`}</li>
        </ul></li>
      <li parentName="ul"><inlineCode parentName="li">{`yarn lerna publish`}</inlineCode></li>
      <li parentName="ul">{`Publish release on GitHub`}</li>
    </ul>
    <h2 {...{
      "id": "troubleshooting"
    }}>{`Troubleshooting`}</h2>
    <p>{`If you’re having issues installing locally you might need to run
`}<inlineCode parentName="p">{`yarn lerna exec yarn install`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`yarn bootstrap`}</inlineCode>{`
(`}<a parentName="p" {...{
        "href": "https://github.com/lerna/lerna/issues/1457"
      }}>{`issue`}</a>{`).`}</p>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/mdx-js/mdx/issues?q=is%3Aissue+is%3Aopen+label%3A%22good+first+issue%22"
        }}>{`Good first issues in the MDX repository`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://opensource.guide/how-to-contribute/"
        }}>{`How to Contribute to Open Source`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://medium.com/@vadimdemedes/making-your-first-contribution-de6576ddb190"
        }}>{`Making your first contribution`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://help.github.com/articles/about-pull-requests/"
        }}>{`Using Pull Requests`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://help.github.com"
        }}>{`GitHub Help`}</a></li>
    </ul>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      